@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.hero {
  &__container {
    width: 100%;
    max-width: 1440px; /* Prevents content from expanding too much */
    margin: 0 auto; /* Centers the container */
    margin-bottom: 2rem;

    @include tablet {
      margin-bottom: 4rem;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include tablet {
      flex-direction: row-reverse;
      align-items: flex-start;
      padding: 0 6%;
    }

    @include desktop {
      padding: 0 6%;
      justify-content: center;
      align-items: flex-start;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;

    &-img {
      width: clamp(12rem, 8vw, 18rem); /* Scales dynamically */
      border-radius: 16px;
    }
  }

  &__section {
    width: 100%;

    @include desktop {
      width: 65%;
      max-width: 800px; /* Limits expansion */
    }
  }

  &__title {
    color: $gold;
    font-size: clamp(1.5rem, 2.5vw, 2rem); /* Scales font size */
    margin: 0 0 1rem 1.5rem;
    padding-top: 6rem;

    @include tablet {
      padding-top: 0;
    }

    @include desktop {
      margin-left: 6rem;
    }
  }

  &__subtitle-text {
    font-size: clamp(0.8rem, 1.2vw, 1.25rem); /* Scales text size */
    line-height: clamp(1.35rem, 2vw, 1.75rem);
    color: $white;
    padding: .5rem;
  }

  &__subtitle-name {
    color: white;
    font-weight: 700;
  }

  &__area {
    background-color: $black;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 1.5rem;
      max-width: 1200px;
      padding: 1rem;
      border-radius: 16px;

      @include tablet {
        padding: 2rem;
      }
    }

    &-title {
      color: $gold;
      font-size: 1.5rem;
    }
  }

  &__skills {
    width: 7.5rem;

    &-subtitle {
      color: white;
      margin: 0;
      padding-bottom: 0.15rem;
    }

    &-text {
      color: $white;
      margin: 0;
      padding: 0.25rem;
    }
  }

  &__button-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}