@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.main {
  margin-bottom: 3rem;

  &__container {
    position: relative;
    width: 100%;
    height: auto; // Default for mobile view
    min-height: 45vh; // Ensures minimum height on small screens
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    @include tablet {
      height: 50vh; // Taller height on tablets
    }

    @include desktop {
      height: 90vh; // Full height on desktop
    }
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url("../../assets/images/coding.jpg"),
      url("../../assets/images/tree.jpg");
    background-position: left top, right bottom;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;

    @include tablet {
      width: 85%;
    }

    @include desktop {
      width: 100%;
    }
  }

  &__section {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__code,
  &__tree {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; // Ensures images scale proportionally
  }

  &__tree {
    opacity: 0.4;
  }

  &__text {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.4);

    @include tablet {
      align-items: center;
    }

    @include desktop {
      padding-top: 8rem;
    }

    &-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-color: rgba(8, 8, 8, 0.4);

      @include tablet {
        padding-left: 3rem;
      }

      @include desktop {
        align-items: center;
      }
    }

    &-intro,
    &-name,
    &-role,
    &-location {
      opacity: 0;
      animation: fadeInUp 1s ease-out forwards;
    }

    &-intro {
      font-size: 1rem;
      color: $gold;
      padding: 0 1.5rem;
      margin: 0;
      animation-delay: 2s;

      @include tablet {
        font-size: 1.25rem;
      }

      @include desktop {
        padding-right: 48rem;
        font-size: 1.5rem;
      }
    }

    &-name {
      font-family: "RobotoBold";
      font-size: 2.8rem;
      color: $white;
      padding: 0.5rem 1.5rem;
      margin: 0;
      animation-delay: 2s;

      @include tablet {
        font-size: 3.5rem;
      }

      @include desktop {
        font-size: 5rem;
      }

      @media (min-width: 1440px) {
        font-size: 6rem;
      }
    }

    &-role {
      margin: 0;
      font-size: 1.56rem;
      color: $gold;
      padding-left: 1.5rem;
      animation-delay: 2s;

      @include tablet {
        font-size: 2rem;
        padding-left: 6.5rem;
      }

      @include desktop {
        padding-left: 0;
        font-size: 2.5rem;
      }

      @media (min-width: 1440px) {
        font-size: 3rem;
      }
    }

    &-location {
      margin: 0;
      padding: 0.5rem 0 0 1.5rem;
      color: $white;
      font-size: 0.9rem;
      animation-delay: 2s;

      @include tablet {
        padding-left: 8rem;
        font-size: 1.25rem;
      }

      @include desktop {
        font-size: 1.75rem;
        padding: 0;
      }

      @media (min-width: 1440px) {
        font-size: 2rem;
      }
    }
  }

  &__social-icons {
    display: flex;
    justify-content: center;
    gap: 0.75rem;

    a {
      transition: color 0.3s ease;

      &:hover {
        color: $gold;
      }
    }
  }

  &__button {
    width: 9rem;
    height: 3rem;
    margin-top: 3rem;
    font-size: 1.75rem;
    color: $black;
    background: linear-gradient(90deg, rgb(227, 227, 228) 0%, rgb(215, 215, 217) 100%);
    border: none;
    border-radius: 100px;
    box-shadow: 4px 4px 14px rgba(194, 194, 195);
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover,
    &:focus {
      color: #313133;
      transform: translateY(-6px);
    }

    &::before {
      content: "";
      border-radius: 1000px;
      min-width: calc(142px + 12px);
      min-height: calc(45px + 12px);
      border: 6px solid #f3f3f4;
      box-shadow: 0 0 60px #818182;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }

    &:hover::before {
      opacity: 1;
    }
  }
}