@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.sidebar {
  display: none;
  @include tablet {
    display: block;
  }
  @include tablet {
    &__left {
      color: $white;
      position: fixed;
      bottom: -16px;
      left: -20px;
      z-index: 10;
      @include desktop {
        left: -16px;
      }
      &-list {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
      }

      &-item {
        font-size: 1.25rem;
        cursor: pointer;
        color: $white;
        a {
          svg {
            transition: transform 0.3s ease-in;
          }
        }
        &:hover {
          color: $gold;
          transform: translateY(-3px);
        }
      }
      &-line {
        height: 6rem;
        border-left: 1px solid $white;
      }
    }
    &__right {
      color: $white;
      position: fixed;
      bottom: -16px;
      right: -75px;
      z-index: 10;
      &-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8rem;
        margin: 0;
        padding: 0;
      }
      &-item {
        transform: rotate(90deg);
        cursor: pointer;
        a {
          transition: transform 0.3s ease-in;
        }
        &:hover {
          color: $gold;
          transform: rotate(90deg) translateX(-3px);
        }
      }
      &-line {
        height: 6rem;
        border-right: 1px solid $white;
      }
    }
  }
}
