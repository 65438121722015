@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;


.card {
  background-color: $black;
  padding: 0 1.5rem;
  @include tablet {
    padding: 4rem;
  }
  &__title {
    font-size: 1.5rem;
    padding: 1rem 0rem 1rem;
    margin: 4rem 0 0 0;
    color: $gold;
    position: sticky;
    top: 50px;
    background-color: $black;
    z-index: 5;
    @include tablet {
      font-size: 2rem;
      padding: 1rem 0 1.5rem 3rem;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    background-color: rgba(37, 37, 37, 0.3);
    padding: 0.75rem;
    border-radius: 1rem;
    position: relative;
    @include tablet {
      gap: 1rem;
      align-items: center;
      padding: 0;
      background-color: transparent;
    }
    @include desktop {
      flex-direction: row;
      gap: 2rem;
      justify-content: center;
      padding: 0;
      background-color: transparent;
      margin-bottom: 6rem;
    }
  }
  &__wrapper {
    margin-top: 0.75rem;
    @include tablet {
      width: 70%;
      background-color: #141414;
      padding: 1rem;
      border-radius: 1rem;
      margin: 0;
    }
    @include desktop {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      width: 26rem;
      gap: 0.5rem;
    }
  }
  &__image {
    position: relative;
    width: 100%;
    border-radius: 8px;
    cursor: pointer;
    &:hover + .card__middle {
      opacity: 1;
      visibility: visible;
    }
    &::before {
      content: "View Live";
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $gold;
      background-color: rgba(37, 37, 37, 0.7);
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
    }
    &:hover {
      opacity: 0.4;
    }
    &:hover + .card__middle .card__text {
      display: block;
    }
    @include tablet {
      width: 70%;
      padding: 0;
    }
    @include desktop {
      width: 40%;
      padding: 0;
    }
  }
  &__subtext {
    margin: 0;
    color: $yellow;
    background-color: black;
    width: fit-content;
  }
  &__subtitle {
    margin: 0;
    color: $gold;
    background-color: black;
    width: fit-content;
    font-size: 1.25rem;
    @include desktop {
      font-size: 1.75rem;
    }
  }
  &__description {
    color: $white;
    text-align: left;
    margin: 0;
    padding-top: 0.5rem;
    font-size: 0.9rem;
  }
  &__language {
    color: white;
    font-size: 1.25rem;
    gap: 1rem;
  }
  &__technologies {
    display: flex;
    gap: 0.5rem;
  }
  &__info {
    color: $yellow;
    cursor: pointer;
    background-color: black;
    padding: 0.1rem;
    width: fit-content;
    margin: 0;
    &:hover {
      color: $yellow-dark;
    }
  }

  &__middle {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  
    &:hover {
      opacity: 1;
      visibility: visible;
    }
  
    @include tablet {
      top: 25%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  
    @include desktop {
      top: 50%;    /* Keep it vertically centered */
      left: 40%;   /* Keep it horizontally centered */
      transform: translate(-50%, -50%);  /* Ensure perfect centering */
    }
  }

  &__text {
    background-color: #000;
    color: white;
    font-size: 1rem;
    padding: 1rem;
    display: block;
  }
}
