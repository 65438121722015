@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.header {
  width: 100%;
  height: 4rem;
  position: fixed;
  top: 0;
  background-color: $black;
  z-index: 10;
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    position: relative;

    @include tablet {
      padding: 0 2rem;
    }

    @include desktop {
      padding: 0 4rem;
    }
  }

  &__logo a {
    display: flex;
    align-items: center;
    color: $white;
    cursor: pointer;

    &:hover {
      color: $gold;
    }
  }

  &__menu {
    font-size: 1.5rem;
    color: $white;
    z-index: 1;
    cursor: pointer;

    @include desktop {
      display: none;
    }
  }

  &__navigation {
    display: none;

    @include desktop {
      display: flex;
      color: $white;

      &-list {
        display: flex;
        gap: 3rem;
      }

      &-item {
        cursor: pointer;

        &:hover {
          color: $gold;
        }
      }
    }
  }

  .mobile-menu {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 40px;
    right: 0;
    transform: translateY(-100%);
    transition: visibility 0.1s linear, opacity 0.3s ease, transform 0.3s ease;
    background-color: $black;
    margin: 1rem;
    @include tablet {
      top: 100%;
      left: 300px;
    }
    &.show {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      margin: 0;
      margin-top: 1rem;
      width: 100%;
      @include tablet {
        position: absolute;
        top: 0;
      }
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-evenly;
      gap: 1rem;
      width: 100%;
      padding: 0 1.5rem;
      @include tablet {
        display: flex;
        align-items: center;
        gap: 0;
        width: 50%;
      }

      li {
        margin: 0.5rem 0;

        a {
          color: $white;
          text-decoration: none;

          &:hover {
            color: $gold;
          }

          &.active {
            color: $gold;
          }
        }
      }
    }
  }
}
