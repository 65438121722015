@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.button {
  display: flex;
  justify-content: center;

  &__link {
    display: block;
    width: 8.5rem;
    line-height: 2rem;
    font-weight: bold;
    text-decoration: none;
    background: transparent;
    text-align: center;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: 3px solid $gold;
    border-radius: 10px;
    transition: all 0.35s;
    margin: 0.5rem;
    padding: 0.25rem;

    &:hover {
      border: 3px solid $gold;
      color: white;
      background-color: $gold;
      font-weight: 700;
      cursor: pointer;
    }
  }
}
