@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.contact {
  width: 100%;
  padding: 1.5rem;

  @include tablet {
    padding: 0rem 8rem;
  }

  @include desktop {
    padding: 0rem 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__container {
    background-color: $black-light;
    padding: 1rem; // Ensure consistent padding
    border-radius: 1rem;
    width: 100%; // Take full width by default
    max-width: 600px; // Limit container width to avoid it stretching too far

    @include tablet {
      padding: 2rem;
    }

    @include desktop {
      width: 60%; // A more proportionate width on desktop
      max-width: 700px; // Prevent overly large container on very large screens
    }
  }

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem; // Consistent padding for spacing
    margin-top: 0.5rem;
    gap: 1rem; // Maintain spacing between elements
  }

  &__title {
    color: $gold;
    margin: 0;
    text-align: center;
    font-size: 1.5rem;

    @include tablet {
      font-size: 2rem;
    }
  }

  &__subtitle {
    color: $white;
    margin: 0;
    font-size: 1.2rem; // Slight adjustment for readability
    padding: 0.5rem;
    text-align: center;
  }

  &__subtext {
    color: $yellow;
    margin: 0;
    font-size: 0.85rem; // Slightly larger for readability
    text-align: center;
  }

  &__form {
    color: $white;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.75rem; // Adjust spacing for better form layout

    &-wrapper {
      display: flex;
      flex-direction: column;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $black-light;
    padding: 1rem;
    border-radius: 1rem;
    width: 100%; 
    max-width: 300px; 

    @include desktop {
      width: 50%; 
    }
  }

  &__status {
    color: $white;
    font-size: 1rem;
    text-align: center;
    background-color: $black-light;
    padding: 0.5rem;
    border-radius: 0.5rem;
    width: 100%; 
    max-width: 400px; 
    margin-top: 0.5rem;
  }
}