@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.projects {
  &__title {
    padding: 0;
    margin: 3rem 1.5rem 1.5rem;
    color: $gold;
    font-size: 1.5rem;
    @include tablet {
      font-size: 2rem;
      margin: 0 0 2rem 8rem;
    }
    @include desktop {
      margin: 0 0 2rem 16rem;
    }
  }
  &__container {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    margin: 0 1.5rem;
    justify-content: center;
    @include tablet {
      padding: 0 4rem;
      flex-direction: row;
      gap: 1rem;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 100%;
    @include tablet {
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      gap: 4rem;
    }
    @include desktop {
      justify-content: center;
      width: 50%;
    }
  }
  &__item {
    width: 100%;
    background-color: rgba(37, 37, 37, 0.3);
    padding: 0.75rem;
    @include desktop {
      padding: 1.5rem;
    }
  }
  &__image {
    width: 100%;
    object-fit: contain;
    @include tablet {
      height: 16rem;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.25rem;
  }

  &__text {
    color: $gold;
    width: fit-content;
    background-color: black;
    font-size: 1.25rem;
    margin: 0;
    @include tablet {
      font-size: 1.65rem;
    }
  }
  &__caption {
    color: $white;
  }
  &__languages {
    color: $white;
    margin: 0;
    display: flex;
    gap: 0.5rem;
    padding: 1rem 0;
    font-size: 1.25rem;
    @include desktop {
      font-size: 2rem;
    }
  }
  &__code {
    color: $yellow;
    cursor: pointer;
    background-color: black;
    width: fit-content;
    &:hover {
      color: $yellow-dark;
    }
  }
  &__work {
    background-color: black;
    color: $yellow;
    width: fit-content;
    margin: 0;
  }
  &__button {
    background-color: transparent;
    border: none;
    font-size: 1.25rem;
    cursor: pointer;
    color: $gold;
    margin: 0;
    padding: 0;
    &:hover {
      color: darken($gold, 15%);
    }
    @include tablet {
      font-size: 2rem;
    }
  }
}
