@use "./mixins" as *;
@use "./colors" as *;
@use "./fonts" as *;

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  font-family: "Roboto", "sans-serif";
  background-color: $black;
  scroll-behavior: smooth;
  padding-top: 4rem;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: unset;
  z-index: 1;
}

input {
  background-color: transparent;
  color: $white;
  padding: 0.25rem;
  margin: 0.15rem 0;
  font-family: "Roboto", "sans-serif";
}

textarea {
  background-color: transparent;
  color: $white;
  resize: none;
  padding: 0.25rem;
  margin: 0.15rem 0;
  font-family: "Roboto", "sans-serif";
}
