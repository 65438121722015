@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.footer {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    @include tablet {
      margin-top: 1rem;
    }
    @include desktop {
      margin-top: 4.5rem;
    }
  }
  &__title {
    color: $white;
    font-size: 0.75rem;
    letter-spacing: 0.5px;
    font-family: "Roboto";
    font-weight: 400;
    line-height: 1.25;
  }
}
